// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-catering-menu-js": () => import("./../../../src/pages/catering-menu.js" /* webpackChunkName: "component---src-pages-catering-menu-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-full-menu-js": () => import("./../../../src/pages/full-menu.js" /* webpackChunkName: "component---src-pages-full-menu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-menu-js": () => import("./../../../src/pages/kids-menu.js" /* webpackChunkName: "component---src-pages-kids-menu-js" */),
  "component---src-pages-order-online-js": () => import("./../../../src/pages/order-online.js" /* webpackChunkName: "component---src-pages-order-online-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-gallery-tamplete-js": () => import("./../../../src/templates/galleryTamplete.js" /* webpackChunkName: "component---src-templates-gallery-tamplete-js" */)
}

